import React,{ useState } from 'react'
import TextField from './TextField'
import moment from 'moment';
import MUITextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';

export default function CoWinNotifier() {

   const [pinCode, setPinCode] = useState('')
   const [age, setAge] = useState('')
   const [showLoader, setShowLoader] = useState(false)
   const [fullName, setFullName] = useState('')
   const [date, setDate] = useState(new Date())
   const [phoneNumber, setPhoneNumber] = useState('91')

   const [centers, setCenters] = useState<any[]>([])

   return (
      <div style={{
         display: 'flex',
         flex: 1,
         flexDirection: 'column',
         alignItems: 'center'
      }}>
         <h1>CoWin Notifier</h1>
         {showLoader === false ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
         }}>
         <TextField
            placeholder='Enter Full Name'
            style={{ marginVertical: 10 }}
            label='Full Name'
            defaultValue={fullName}
            onChangeText={(text) => setFullName(text)}
            value={fullName} />
         <TextField
            placeholder='Enter Age'
            style={{ marginVertical: 10 }}
            label='Age'
            defaultValue={age}
            onChangeText={(text) => setAge(text)}
            value={age} />
         <TextField
            placeholder='Enter Pin Code'
            style={{ marginVertical: 10 }}
            label='Pin Code'
            defaultValue={pinCode}
            onChangeText={(text) => setPinCode(text)}
            value={pinCode} />
         <TextField
            placeholder='Enter Phone'
            style={{ marginVertical: 10 }}
            label='Phone Number'
            defaultValue={phoneNumber}
            onChangeText={(text) => setPhoneNumber(text)}
            value={phoneNumber} />
         <MUITextField
            style={{ marginTop: 10 }}
            variant='outlined'
            id="date"
            label="Settlement Date"
            type="date"
            defaultValue={moment(date).format('YYYY-MM-DD')}
            InputLabelProps={{
               shrink: true,
            }}
            onChange={(event) => onChangeValue(event.target.value)} />
         </div> : <div>
            <h1>Alright. We will send you a WhatsApp Message when a center is available near you!</h1>
         </div>}
         <Button 
            style={{ marginTop: 10 }}
            variant='contained'
            onClick={onPressStartNotifier}>START NOTIFIER</Button>
         <h5>We will not sell your name or phone number or any other information to any 3rd party. All the data shall be deleted once you are notified.</h5>
         {centers.length > 0 &&
            <MaterialTable
               data={centers}
               columns={
                  [
                     {
                        render: (center) => JSON.stringify(center)
                     }
                  ]
               }
            />}
      </div>
   )

   function onChangeValue(value:any){
      const date = new Date(value)
      setDate(date)
   }

   async function onPressStartNotifier(){

      setShowLoader(true)

      const __date = moment(date).format('DD-MM-YYYY')
      const url = `https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/calendarByPin?pincode=${pinCode}&date=${__date}`

      const response = await fetch(url)
      if(response.ok === true){
         const JSON = (await response.json()) as any
         const centers = JSON.centers as any[]
         if(centers.length > 0){
            setCenters(centers)
            //Do some stuff like notify
         }else{
            setCenters([])
         }
         console.log('JSON',JSON)
      }

      setShowLoader(false)
   }
}