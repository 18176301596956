import React,{ useState, useEffect } from 'react';
import './App.css';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import InputAdornment from '@material-ui/core/InputAdornment'
import firebase from 'firebase'
import NumberFormat from 'react-number-format';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import CoWinNotifier from './CoWinNotifier';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';

firebase.initializeApp({
  apiKey: "AIzaSyB3MV5TSj6ftyLSnYHB8_JD7Oci3viWHl4",
  authDomain: "to-whats-app.firebaseapp.com",
  databaseURL: "https://to-whats-app.firebaseio.com",
  projectId: "to-whats-app",
  storageBucket: "to-whats-app.appspot.com",
  messagingSenderId: "463826087804",
  appId: "1:463826087804:web:3204b0b38bcd865a08fef9",
  measurementId: "G-5NRPN1YRMT"
})

firebase.analytics();

const documentPath = 'statistics/total'

function App() {

  const [phoneNumber, setPhoneNumber] = useState('')
  const [totalMessages, setTotalMessages] = useState(0)
  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    firebase.firestore().doc(documentPath)
      .get()
      .then(
        (response) => {
          const doc = response.data()
          const totalMessages = doc?.totalMessages ?? 0
          setTotalMessages(totalMessages)
        }
    )
      .catch(error => console.log('Failed to Fetch : ', error))

    window.navigator.clipboard.readText()
      .then((text) => {
        const __phoneNumber = text?.replaceAll(/[^0-9.]+/g, "")
        if(__phoneNumber.length >= 10){
          setPhoneNumber(text)
        }
      })
      .catch(() => {
        firebase.analytics().logEvent('PASTE_FAILED')
      })
  }, [])

  return (
    <div style={{
        display : 'flex',
        flex : 1,
        justifyContent : 'space-between',
        alignItems : 'center',
        flexDirection : 'column'
    }}>
      <AddToHomeScreen />
      <div style={{
        marginTop: 50
      }}>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding : 10,
        alignItems : 'center',
        width : '100%',
        maxWidth : 300,
        marginBottom : '10%'
      }}>
        <Avatar style={{ 
          borderRadius : 20, 
          height : 100, 
          width : 100,
          marginBottom : 10
        }} src={require('./res/logo.png')}/>
        <div style={{
          marginBottom : 15
        }}>
          <Typography variant='h6' style={{
            fontWeight : 'normal',
            fontSize : 30
          }}>WhatsApp → To</Typography>
          <Typography style={{
            color : 'gray'
          }}>Simply type the recipients WhatsApp phone number that aren't in your address book.</Typography>
        </div>
        <TextField
          style={{
            marginBottom: 10,
            width : '100%'
          }}
          autoFocus
          inputProps={{ inputMode: 'numeric' }}
          variant='outlined'
          placeholder='Enter a number with country code'
          value={phoneNumber}
          InputProps={{
            endAdornment: (
              <InputAdornment
                style={{
                  marginRight: -20
                }}
                position="end">
                {phoneNumber.length > 0 && <Button 
                  style={{
                    color : 'gray',
                    fontSize : 20,
                    padding : 0
                  }}
                  onClick={
                  () => setPhoneNumber('')
                }>🅧</Button>}
              </InputAdornment>
            ),
          }}
          onChange={(event) => setPhoneNumber(event.target.value.replaceAll(/[^0-9.]+/g, "") )} />
        <Button 
          style={{
            background : 'rgb(25,198,55)',
            color : 'white',
            width : '100%',
            height : 50
          }}          
          disabled={!(phoneNumber.length > 0)}
          disableElevation
          variant='contained'
          onClick={onClickSend}>Send</Button>  
        {errorText.length > 0 && <Typography style={{
          fontSize: 13,
          color: 'red'
        }}>{errorText}</Typography>}
        <Typography style={{
          marginTop: 5,
          fontSize: 12,
          color: 'gray'
        }}>BECAUSE PRIVACY IS IMPORTANT!</Typography>      
      </div>
      <div style={{

      }}>
        {totalMessages > 10 && <div style={{
          // marginTop: '10%',
          // marginBottom: '10%'
        }}>
          <NumberFormat 
            style={{
              fontSize : 20,
              background : 'white',
              padding : 7,
              paddingLeft : 20,
              paddingRight : 15,
              color : 'black',
              borderRadius : 5,
              letterSpacing : 5,
              textAlign : 'center',
              fontWeight : 'bold'
            }}
            value={totalMessages} 
            displayType={'text'} 
            thousandSeparator={true}/>
          <Typography style={{
            color : 'gray',
            marginTop : 10,
            fontSize : 10
          }}>TIMES WHATSAPP→TO WAS USED</Typography>
        </div>}
      </div>
    </div>
  );

  function onClickSend() {

    if(phoneNumber.length <= 10){
      setErrorText('Please enter phone number along with country code')
      return
    }else{
      setErrorText('')
    }

    const increment = firebase.firestore.FieldValue.increment(1)
    firebase.firestore().doc(documentPath).update({
      totalMessages: increment
    })
      .then(
        () => {
          setTotalMessages(totalMessages + 1)
        }
      )
      .catch(error => console.log('Failed to Increment : ', error))
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber.trim()}&text=`, "_self")
  }

}

export default () => (
  <div className="App" style={{
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  }}>
    <Router>
      <Switch>
        <Route exact path='/'><App /></Route>
        <Route path='/cowin'><CoWinNotifier /></Route>
      </Switch>
    </Router>
    <div style={{
        display: 'flex'
      }}>
        <Typography variant='h6' style={{
          fontWeight: 'normal',
          fontSize: 13,
          color: 'gray'
        }}>Powered by LogiŠian • </Typography>
        <a style={{
          textDecoration: 'none',
          color: 'black'
        }} href='http://www.logisian.in'>
          <Typography variant='h6' style={{
            fontWeight: 'normal',
            fontSize: 13,
            color: 'gray'
          }}>www.logisian.in</Typography>
        </a>
      </div>
  </div>
);
